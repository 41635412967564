@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  font-family: "Montserrat";
  color: var(--text-color);
  letter-spacing: 1px;
  transition: background-color 0.3s linear;
}

h1 {
  font-size: 3.5rem;
  line-height: 3.5rem;
}

h2,
label {
  line-height: 2.2rem;
  font-size: 2.3rem;
}

p {
  line-height: 1.7rem;
  font-size: 1.3rem;
}

img {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  list-style-type: none;
}

input,
textarea {
  margin: 5px 0;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  letter-spacing: 1.5px;
  outline: none;
  border: none;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  border-radius: 10px;
  color: var(--text-color);
}
input:hover, input:focus,
textarea:hover,
textarea:focus {
  box-shadow: var(--shadow2);
}

label {
  font-weight: bold;
  font-size: 1.4rem;
}

.App {
  position: relative;
}

.btn {
  border-radius: 0.55rem;
  font-size: 1.2rem;
  outline: none;
  border: none;
  padding: 0.55rem 0.95rem;
  cursor: pointer;
  margin: 10px 0;
  font-weight: bold;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  font-family: "Raleway", sans-serif !important;
  color: var(--text-color);
}
.btn:hover {
  box-shadow: var(--shadow2);
}

.section {
  display: grid;
  min-height: 100vh;
  margin: 50px auto;
}
.section .heading {
  place-self: center;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  padding: 15px;
  margin-top: 20px;
}
.section .heading:hover {
  box-shadow: var(--shadow2);
}

.inputField {
  margin: 10px auto;
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 2.5rem;
  }

  h2,
label {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
.not-header {
  margin-left: 80px;
}

@media screen and (max-width: 700px) {
  .not-header {
    margin-left: 0;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  background-color: var(--bg-color);
  z-index: 10;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
}

.header__list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  transition: all 0.3s linear;
  padding: 5px;
  transition: all 0.3s linear;
}

.header__listItem {
  margin: 10px auto;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
}
.header__listItem a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.3rem;
  color: var(--text-color);
  padding: 10px;
}
.header__listItem a p {
  font-size: 1.1rem;
  margin-left: 10px;
  display: none;
}
.header__listItem:hover {
  box-shadow: var(--shadow2);
}

.open .header__list {
  overflow-y: scroll;
  max-height: 100vh;
}
.open .header__list .header__listItem {
  margin: 10px;
}
.open .header__list .header__listItem a p {
  display: block;
}
.open .header__list .header__listItem:hover {
  box-shadow: var(--shadow2);
}

@media screen and (max-width: 700px) {
  .header {
    min-height: 0%;
    margin: 10px;
  }

  .header__listItem {
    display: none;
  }

  .header__list {
    padding: 0;
  }

  .menubtn {
    display: block;
    margin: 0;
  }

  .open {
    margin: 0;
    min-height: 100%;
  }
  .open .header__list .header__listItem {
    display: block;
    margin: 10px;
  }
  .open .header__list .header__listItem a p {
    display: block;
  }
}
.container {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 5px;
  background-color: var(--text-color);
  transition: 0.4s;
}

.bar2 {
  margin: 6px 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.main main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.7;
  text-align: center;
}
.main section {
  flex: 0.3;
  display: grid;
  place-items: center;
}

.main__heading {
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  padding: 20px;
}
.main__heading:hover {
  box-shadow: var(--shadow2);
}
.main__heading span {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2px;
}

@media screen and (max-width: 700px) {
  .main main {
    padding: 20px;
    margin-left: 0;
    margin: 0 auto;
    justify-content: center;
  }

  .main__heading span {
    font-size: 1.7rem;
  }
}
.aboutMe {
  padding: 10px;
  min-height: 100vh;
}
.aboutMe .heading {
  margin: 10px auto;
  text-align: center;
  margin-bottom: 50px;
}
.aboutMe .content {
  display: flex;
  justify-content: space-evenly;
  width: 80vw;
  margin: 0 auto;
  align-items: center;
}
.aboutMe__para {
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  letter-spacing: 1.3px;
  font-size: 1.2rem;
  line-height: 2rem;
  flex: 0.8;
  margin-bottom: 20px;
  padding: 20px;
}
.aboutMe__para:hover {
  box-shadow: var(--shadow2);
}
.aboutMe .imgContainer {
  position: relative;
  max-width: 500px;
  min-height: 200px;
  min-width: 200px;
  overflow: hidden;
  transition: all 1s linear;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
}
.aboutMe .imgContainer:hover {
  box-shadow: var(--shadow2);
}
.aboutMe a {
  color: var(--text-color);
  font-weight: bold;
}
.aboutMe a:hover {
  text-decoration: underline;
}

.img-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: white;
  transition: all 0.3s ease-in-out;
}

.imgContainer:hover .img-overlay {
  transform: translateY(-101%);
}

@media screen and (max-width: 1200px) {
  .aboutMe .heading {
    margin-bottom: 30px;
  }
  .aboutMe .content {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.contactMe {
  margin-bottom: 0;
}
.contactMe main {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.contactMe__socialsLink {
  font-size: 2rem;
  margin-top: 30px;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
}
.contactMe__socialsLink:hover {
  box-shadow: var(--shadow2);
}
.contactMe__socialsLink h2 {
  margin-bottom: 20px;
}
.contactMe__socialsLink a {
  color: var(--text-color);
}
.contactMe__socialsLink a section {
  padding: 15px;
}
.contactMe__socialsLink a:hover p {
  text-decoration: underline;
}
.contactMe__socialsLink a div {
  display: flex;
}
.contactMe__socialsLink a p {
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .contactMe main {
    flex-direction: column;
    padding: 0 20px;
  }

  .contactMe__form {
    margin-top: 20px;
  }
}
.skills {
  padding: 20px;
}
.skills h1 {
  margin-bottom: 1.5rem;
}
.skills .cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  gap: 20px;
}
.skills .cards .card {
  display: flex;
  align-items: center;
  max-width: 250px;
  width: 100%;
  height: 100px;
  padding: 1rem;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
}
.skills .cards .card:hover {
  box-shadow: var(--shadow2);
}
.skills .cards .card .logo-container {
  max-width: 70px;
  width: 100%;
  margin-right: 30px;
  min-height: 50px;
  min-width: 50px;
}
@media screen and (max-width: 1000px) {
  .skills .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 550px) {
  .skills .cards {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
}

.certificates main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  margin: 0 20px;
}
.certificates .heading {
  margin-bottom: 50px;
}
.certificates .card {
  margin: 0 auto;
  padding: 20px;
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  max-width: 500px;
}
.certificates .card:hover {
  box-shadow: var(--shadow2);
}
.certificates .card .imgContainer {
  position: relative;
  max-width: 400px;
  min-height: 200px;
  min-width: 200px;
  margin: 10px auto;
  overflow: hidden;
}
.certificates .card .card-details {
  max-width: 400px;
}
.certificates .card .card-details span {
  font-weight: bold;
}
@media screen and (max-width: 1200px) {
  .certificates main {
    grid-template-columns: 1fr;
  }
}

.project main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 85vw;
  margin: 0 auto;
}
.project .projectTitle {
  text-align: center;
  margin: 40px 0;
}
.project .imgContainer {
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  position: relative;
  overflow: hidden;
  max-width: 600px;
  min-height: 200px;
  min-width: 200px;
}
.project .imgContainer:hover {
  box-shadow: var(--shadow2);
}
.project .projectDetails {
  border: 0.0625rem solid transparent;
  box-shadow: var(--shadow1);
  border-radius: 10px;
  background-color: var(--bg-color);
  flex: 0.8;
  padding: 10px;
}
.project .projectDetails:hover {
  box-shadow: var(--shadow2);
}
.project .projectLinks {
  margin-top: 10px;
}
.project .projectLinks a {
  color: var(--text-color);
}
.project .projectLinks a:hover {
  text-decoration: underline;
}
.project:nth-child(even) main {
  flex-direction: row-reverse;
}
@media screen and (max-width: 1200px) {
  .project main {
    flex-direction: column !important;
    max-width: 650px;
  }
  .project .imgContainer {
    margin-bottom: 20px;
    min-height: 200px;
    min-width: 200px;
  }
}

.experience {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.experience .sessions {
  margin-top: 5rem;
  border-radius: 12px;
  position: relative;
  padding: 0 20px;
}
.experience li {
  padding-bottom: 1.5rem;
  border-left: 1px solid;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
.experience li .company {
  font-weight: bold;
}
.experience li .position {
  font-size: medium;
}
.experience li .url {
  color: var(--text-color);
  text-decoration: underline;
}
.experience li:last-child {
  border: 0px;
  padding-bottom: 0;
}
.experience li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: var(--text-color);
  border: 1px solid;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 0px;
}
.experience .time {
  font-weight: 500;
}
@media screen and (min-width: 601px) {
  .experience .time {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 600px) {
  .experience .time {
    margin-bottom: 0.3rem;
    font-size: 0.85rem;
  }
}
.experience p {
  line-height: 1.5;
  margin-top: 0.4rem;
}
@media screen and (max-width: 600px) {
  .experience p {
    font-size: 0.9rem;
  }
}

