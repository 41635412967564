@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
#root {
	$bg-color: #e6e7ee;
	$text-color: #31344b;
	$shadow1: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
	$shadow2: 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
}
@mixin theme1 {
	border: 0.0625rem solid transparent;
	box-shadow: var(--shadow1);
	border-radius: 10px;
	background-color: var(--bg-color);
}
@mixin theme2 {
	box-shadow: var(--shadow2);
}

@mixin tablet-and-up {
	@media screen and (min-width: 769px) {
		@content;
	}
}
@mixin mobile-and-up {
	@media screen and (min-width: 601px) {
		@content;
	}
}
@mixin tablet-and-down {
	@media screen and (max-width: 768px) {
		@content;
	}
}
@mixin mobile-only {
	@media screen and (max-width: 600px) {
		@content;
	}
}

body {
	background-color: var(--bg-color);
	font-family: "Montserrat";
	color: var(--text-color);
	letter-spacing: 1px;
	transition: background-color 0.3s linear;
}
h1 {
	font-size: clamp(2.8rem, 3.5rem, 4rem);
	line-height: 3.5rem;
}
h2,
label {
	line-height: 2.2rem;
	font-size: clamp(2rem, 2.3rem, 2.8rem);
}
p {
	line-height: 1.7rem;
	font-size: clamp(1rem, 1.3rem, 1.7rem);
}

img {
	width: 100%;
	height: 100%;
}
a {
	text-decoration: none;
	color: black;
}
ul {
	list-style-type: none;
}
input,
textarea {
	margin: 5px 0;
	width: 100%;
	padding: 10px;
	font-size: 1rem;
	letter-spacing: 1.5px;
	outline: none;
	border: none;
	@include theme1;
	border-radius: 10px;
	&:hover,
	&:focus {
		@include theme2();
	}
	color: var(--text-color);
}

label {
	font-weight: bold;
	font-size: 1.4rem;
}
.App {
	position: relative;
}
.btn {
	border-radius: 0.55rem;
	font-size: 1.2rem;
	outline: none;
	border: none;
	padding: 0.55rem 0.95rem;
	cursor: pointer;
	margin: 10px 0;
	font-weight: bold;
	@include theme1;
	&:hover {
		@include theme2;
	}
	font-family: "Raleway", sans-serif !important;
	color: var(--text-color);
}
.section {
	display: grid;
	min-height: 100vh;
	& .heading {
		place-self: center;
		@include theme1();
		&:hover {
			@include theme2();
		}
		padding: 15px;
		margin-top: 20px;
	}
	margin: 50px auto;
}
.inputField {
	margin: 10px auto;
}
@media screen and (max-width: 700px) {
	h1 {
		font-size: 2.5rem;
	}
	h2,
	label {
		font-size: 1.5rem;
	}
	p {
		font-size: 1rem;
	}
}
.not-header {
	margin-left: 80px;
}
@media screen and (max-width: 700px) {
	.not-header {
		margin-left: 0;
	}
}
// Header
.header {
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100%;
	background-color: var(--bg-color);
	z-index: 10;
	@include theme1();
}
.header__list {
	list-style-type: none;
	display: flex;
	flex-direction: column;
	font-weight: bold;
	transition: all 0.3s linear;
	padding: 5px;
	transition: all 0.3s linear;
}
.header__listItem {
	margin: 10px auto;
	@include theme1();
	a {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 1.3rem;
		color: var(--text-color);
		padding: 10px;
		p {
			font-size: 1.1rem;
			margin-left: 10px;
			display: none;
		}
	}
	&:hover {
		@include theme2();
	}
}
.open {
	.header__list {
		overflow-y: scroll;
		max-height: 100vh;
		.header__listItem {
			margin: 10px;
			a {
				p {
					display: block;
				}
			}
			&:hover {
				@include theme2();
			}
		}
	}
}
@media screen and (max-width: 700px) {
	.header {
		min-height: 0%;
		margin: 10px;
	}
	.header__listItem {
		display: none;
	}
	.header__list {
		padding: 0;
	}
	.menubtn {
		display: block;
		margin: 0;
	}
	.open {
		margin: 0;
		min-height: 100%;
		.header__list {
			.header__listItem {
				display: block;
				margin: 10px;
				a {
					p {
						display: block;
					}
				}
			}
		}
	}
}
.container {
	padding: 10px;
	display: inline-block;
	cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
	width: 30px;
	height: 5px;
	background-color: var(--text-color);
	transition: 0.4s;
}
.bar2 {
	margin: 6px 0;
}
.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-9px, 6px);
	transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-8px, -8px);
	transform: rotate(45deg) translate(-8px, -8px);
}

// Main

.main {
	height: 100vh;
	display: flex;
	flex-direction: column;
	main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0.7;
		text-align: center;
	}
	section {
		flex: 0.3;
		display: grid;
		place-items: center;
	}
}
.main__heading {
	@include theme1();
	padding: 20px;
	&:hover {
		@include theme2();
	}
	span {
		font-weight: 400;
		font-size: 2rem;
		line-height: 2px;
	}
}

@media screen and (max-width: 700px) {
	.main {
		main {
			padding: 20px;
			margin-left: 0;
			margin: 0 auto;
			justify-content: center;
		}
	}
	.main__heading {
		span {
			font-size: 1.7rem;
		}
	}
}

// About Me
.aboutMe {
	padding: 10px;
	min-height: 100vh;
	.heading {
		margin: 10px auto;
		text-align: center;
		margin-bottom: 50px;
	}
	.content {
		display: flex;
		justify-content: space-evenly;
		width: 80vw;
		margin: 0 auto;
		align-items: center;
	}

	&__para {
		@include theme1();
		&:hover {
			@include theme2();
		}
		letter-spacing: 1.3px;
		font-size: 1.2rem;
		line-height: 2rem;
		flex: 0.8;
		margin-bottom: 20px;
		padding: 20px;
	}
	.imgContainer {
		position: relative;
		max-width: 500px;
		min-height: 200px;
		min-width: 200px;
		overflow: hidden;
		transition: all 1s linear;
		@include theme1();
		&:hover {
			@include theme2();
		}
	}
	a {
		color: var(--text-color);
		font-weight: bold;
		&:hover {
			text-decoration: underline;
		}
	}
}
.img-overlay {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	color: white;
	transition: all 0.3s ease-in-out;
}
.imgContainer:hover .img-overlay {
	transform: translateY(-101%);
}
@media screen and (max-width: 1200px) {
	.aboutMe {
		.heading {
			margin-bottom: 30px;
		}
		.content {
			max-width: 650px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
}
// Contact Me
.contactMe {
	margin-bottom: 0;
	main {
		padding: 10px;
		display: flex;
		justify-content: space-around;
	}
}
.contactMe__socialsLink {
	font-size: 2rem;
	margin-top: 30px;
	@include theme1();
	&:hover {
		@include theme2();
	}
	h2 {
		margin-bottom: 20px;
	}
	a {
		section {
			padding: 15px;
		}
		color: var(--text-color);
		&:hover {
			p {
				text-decoration: underline;
			}
		}
		div {
			display: flex;
		}
		p {
			margin-left: 10px;
		}
	}
}
@media screen and (max-width: 700px) {
	.contactMe {
		main {
			flex-direction: column;
			padding: 0 20px;
		}
	}
	.contactMe__form {
		margin-top: 20px;
	}
}
// Skills
.skills {
	h1 {
		margin-bottom: 1.5rem;
	}
	padding: 20px;
	.cards {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		place-items: center;
		gap: 20px;
		.card {
			display: flex;
			align-items: center;
			max-width: 250px;
			width: 100%;
			height: 100px;
			padding: 1rem;
			@include theme1();
			&:hover {
				@include theme2();
			}
			.logo-container {
				max-width: 70px;
				width: 100%;
				margin-right: 30px;
				min-height: 50px;
				min-width: 50px;
			}
		}
	}
	@media screen and (max-width: 1000px) {
		.cards {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	@media screen and (max-width: 550px) {
		.cards {
			margin-top: 50px;
			display: flex;
			flex-direction: column;
		}
	}
}
// Cirtificates
.certificates {
	main {
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 30px;
		margin: 0 20px;
	}
	.heading {
		margin-bottom: 50px;
	}
	.card {
		margin: 0 auto;
		padding: 20px;
		@include theme1();
		max-width: 500px;
		&:hover {
			@include theme2();
		}
		.imgContainer {
			position: relative;
			max-width: 400px;
			min-height: 200px;
			min-width: 200px;
			margin: 10px auto;
			overflow: hidden;
		}
		.card-details {
			max-width: 400px;
			span {
				font-weight: bold;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		main {
			grid-template-columns: 1fr;
		}
	}
}
// Project
.project {
	main {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		width: 85vw;
		margin: 0 auto;
	}
	.projectTitle {
		text-align: center;
		margin: 40px 0;
	}

	.imgContainer {
		@include theme1();
		position: relative;
		overflow: hidden;
		max-width: 600px;
		min-height: 200px;
		min-width: 200px;
		&:hover {
			@include theme2();
		}
	}
	.projectDetails {
		@include theme1();
		flex: 0.8;
		padding: 10px;
		&:hover {
			@include theme2();
		}
	}
	.projectLinks {
		margin-top: 10px;
		a {
			color: var(--text-color);
		}
		a:hover {
			text-decoration: underline;
		}
	}
	&:nth-child(even) main {
		flex-direction: row-reverse;
	}
	@media screen and (max-width: 1200px) {
		main {
			flex-direction: column !important;
			max-width: 650px;
		}
		.imgContainer {
			margin-bottom: 20px;
			min-height: 200px;
			min-width: 200px;
		}
	}
}
// Experience
.experience {
	display: flex;
	flex-direction: column;
	align-items: center;
	.sessions {
		margin-top: 5rem;
		border-radius: 12px;
		position: relative;
		padding: 0 20px;
	}
	li {
		padding-bottom: 1.5rem;
		border-left: 1px solid;
		position: relative;
		padding-left: 20px;
		margin-left: 10px;
		.company {
			font-weight: bold;
		}
		.position {
			font-size: medium;
		}
		.url {
			color: var(--text-color);
			text-decoration: underline;
		}
		&:last-child {
			border: 0px;
			padding-bottom: 0;
		}
		&:before {
			content: "";
			width: 15px;
			height: 15px;
			background: var(--text-color);
			border: 1px solid;
			border-radius: 50%;
			position: absolute;
			left: -10px;
			top: 0px;
		}
	}
	.time {
		font-weight: 500;
		@include mobile-and-up {
			font-size: 0.9rem;
		}
		@include mobile-only {
			margin-bottom: 0.3rem;
			font-size: 0.85rem;
		}
	}
	p {
		line-height: 1.5;
		margin-top: 0.4rem;
		@include mobile-only {
			font-size: 0.9rem;
		}
	}
}
